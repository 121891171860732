import { gql } from "@apollo/client";

const REGISTER_USER = gql`
  mutation MyMutation(
    $address1: String
    $address2: String
    $email: String
    $medicalProfessionalName: String
    $medicalLicenceNumber: String
    $profession: String
    $specialty: String
    $licenceExpirationDate: String
    $howDidYouHearAboutUs: [String]
    $phoneNumber: String
    $websiteUrl: String
    $firstName: String
    $lastName: String
    $password: String
    $city: String
    $company: String
    $postcode: String
    $state: String
    $country: CountriesEnum
    $phone: String
    $preferredCommunication: String
    $contactOrderName: String
    $contactPhone: String
    $contactEmail: String
  ) {
    registerCustomer(
      input: {
        billing: {
          address1: $address1
          address2: $address2
          city: $city
          company: $company
          postcode: $postcode
          state: $state
          country: $country
          phone: $phone
        }
        websiteUrl: $websiteUrl
        email: $email
        medicalProfessionalName: $medicalProfessionalName
        profession: $profession
        specialty: $specialty
        medicalLicenceNumber: $medicalLicenceNumber
        licenceExpirationDate: $licenceExpirationDate
        howDidYouHearAboutUs: $howDidYouHearAboutUs
        phoneNumber: $phoneNumber
        firstName: $firstName
        lastName: $lastName
        password: $password
        preferredCommunication: $preferredCommunication
        contactOrderName: $contactOrderName
        contactPhone: $contactPhone
        contactEmail: $contactEmail
      }
    ) {
      customer {
        databaseId
        firstName
        lastName
      }
    }
  }
`;

export default REGISTER_USER;
