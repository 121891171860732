import * as React from "react";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import RegisterWrapper from "../components/Register/RegisterWrapper";
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const RegisterAcc: FunctionComponent = () => {
  return (
    <>
      <Layout>
        <UnAuthContent>
          <RegisterWrapper />
        </UnAuthContent>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Doctormedica.co | Registration"
    description="Register with DoctorMedica.co and start saving on medical devices, aesthetics accessories and more."
  />
);

export default RegisterAcc;
