import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { IconMathGreater } from "@tabler/icons";

const Step1 = ({
  onContinueClick,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  emailError,
  passwordError,
  confirmPasswordError,
  isTermsChecked,
  setIsTermsChecked,
  isTermsCheckedError,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <VStack width="full" maxW="1612px" mx="auto" alignItems='center' justifyContent='center'>
      <VStack
        pb={{ base: "0px", sm: "0px", md: 6 }}
        width="full"
        alignItems="flex-start"
      >
        <Flex
          flexDir={{ base: "column", sm: "column", md: "row" }}
          alignItems="flex-start"
          w={{ base: '100%', md: '512px' }} 
          gap={{ base: 0, sm: 0, md: 6 }}
		  mx='auto'
        >
          <FormControl isRequired isInvalid={!!emailError}>
            <FormLabel pt={{ base: "20px", sm: "20px", md: "0px" }}>
              Email address
            </FormLabel>
            <Input
              border="1px solid #27272A"
              _hover={{ border: "1px solid #27272A" }}
              _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
			  h='56px'
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex
          gap={{ base: 0, sm: 0, md: 6 }}
          flexDir={{ base: "column", sm: "column", md: "row" }}
          alignItems="flex-start"
          w={{ base: '100%', md: '512px' }} 
		  mx='auto'
        >
          <FormControl isRequired isInvalid={!!passwordError}>
            <FormLabel pt={{ base: "20px", sm: "20px", md: "0px" }}>
              Your password
            </FormLabel>
            <InputGroup>
              <Input
                border="1px solid #27272A"
                _hover={{ border: "1px solid #27272A" }}
                _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
				h='56px'
              />
              <InputRightElement>
                <Button
                  onClick={() => setShowPassword(!showPassword)}
                  background="transparent"
                  color="gray.700"
                  _hover={{ bg: "transparent", border: "none" }}
                  _focus={{ border: "none" }}
				          top='8px'
                >
                  {showPassword ? (
                    <Icon as={AiFillEyeInvisible} />
                  ) : (
                    <Icon as={AiFillEye} />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>{passwordError}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex
          gap={{ base: 0, sm: 0, md: 6 }}
          flexDir={{ base: "column", sm: "column", md: "row" }}
          alignItems="flex-start"
          w={{ base: '100%', md: '512px' }} 
		  mx='auto'
        >
          <FormControl
            mb={{ base: "48px", sm: "48px", md: "0px" }}
            isRequired
            isInvalid={!!confirmPasswordError}
          >
            <FormLabel pt={{ base: "20px", sm: "20px", md: "0px" }}>
              Confirm your password
            </FormLabel>
            <InputGroup>
              <Input
                border="1px solid #27272A"
                _hover={{ border: "1px solid #27272A" }}
                _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? "text" : "password"}
				h='56px'
              />
              <InputRightElement>
                <Button
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  background="transparent"
                  color="gray.700"
                  _hover={{ bg: "transparent", border: "none" }}
                  _focus={{ border: "none" }}
				  top='8px'
                >
                  {showConfirmPassword ? (
                    <Icon as={AiFillEyeInvisible} />
                  ) : (
                    <Icon as={AiFillEye} />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Stack mx='auto' w={{ base: '100%', md: '512px' }}  alignItems="flex-start">
          <VStack alignItems="flex-start" minH="20px">
            <HStack flexWrap={'wrap'}>
              <Checkbox
                isChecked={isTermsChecked}
                onChange={() => setIsTermsChecked(!isTermsChecked)}
                fontWeight="bold"
                colorScheme="green"
              >
                I agree to
              </Checkbox>
              <Link
                href="/use-policy"
                fontSize="16px"
                fontWeight="bold"
                color="#DCA672"
                target="_blank"
                rel="noopener"
              >
                Terms & Conditions
              </Link>
			  <Text
                fontWeight="bold"
                colorScheme="green"
              >
                and
              </Text>
              <Link
                href="/customer-agreement"
                fontSize="16px"
                fontWeight="bold"
                color="#DCA672"
                target="_blank"
                rel="noopener"
              >
                Customer Agreement
              </Link>
            </HStack>
            <Text color="red">{isTermsCheckedError}</Text>
          </VStack>
          <VStack flexWrap='wrap' alignItems="flex-start" minH="20px" w={{ base: '100%', md: '512px' }} >
            <Checkbox pb="10px" fontWeight="bold" colorScheme="green">
              I agree to receive promotional text messages and understand that I
              can opt out at any time.
            </Checkbox>
          </VStack>
        </Stack>
      </VStack>
      <Button
        onClick={onContinueClick}
        w="160px"
        h="40px"
        type="button"
        fontSize="14px"
        color="black"
        bg="white"
        border="1px solid black"
        borderRadius="48px"
        fontWeight="700"
        transition="0.3s"
        _hover={{ color: "white", bg: "black" }}
        rightIcon={<IconMathGreater stroke="4px" width="12px" height="12px" />}
      >
        CONTINUE
      </Button>
    </VStack>
  );
};

export default Step1;
