import { graphql, useStaticQuery } from "gatsby";

export const getAllUSAStates = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        wooStates(countryCode: "US") {
          states {
            stateName
            stateCode
          }
        }
      }
    }
  `);
  return data.wp.wooStates.states;
};
