import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Select as ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { IconMathGreater, IconMathLower } from "@tabler/icons";
import React, { useState } from "react";
import { Select } from "chakra-react-select";

const Step2 = ({
  onContinueClick,
  onBackClick,
  firstName,
  setFirstName,
  phoneNumber,
  setPhoneNumber,
  faxNumber,
  setFaxNumber,
  address1,
  setAddress1,
  address2,
  setAddress2,
  city,
  setCity,
  zipCode,
  setZipCode,
  state,
  setState,
  options,
  firstNameError,
  phoneNumberError,
  address1Error,
  cityError,
  zipCodeError,
  stateError,
  contactOrderName,
  setcontactOrderName,
  contactPhone,
  setContactPhone,
  contactEmail,
  setContactEmail,
  contactOrderNameError,
  contactPhoneError,
}: any) => {
  const [isOrderChecked, setIsOrderChecked] = useState(false);
  const [errors, setErrors] = useState<any>({
    contactOrderNameError: "",
    contactPhoneError: "",
  });

  const validateFields = () => {
    let isValid = true;
    const newErrors: any = {};

    if (isOrderChecked) {
      if (!contactOrderName) {
        newErrors.contactOrderNameError = "Contact name is required.";
        isValid = false;
      }
      if (!contactPhone) {
        newErrors.contactPhoneError = "Contact phone is required.";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleContinueClick = () => {
    if (validateFields()) {
      onContinueClick();
    }
  };

  return (
    <VStack w={{ base: "100%", md: "512px" }} mx="auto" maxW="1612px">
      {/* Your existing fields */}
      <FormControl isRequired isInvalid={!!firstNameError}>
        <FormLabel>Your full name</FormLabel>
        <Input
          border="1px solid #27272A"
          _hover={{ border: "1px solid #27272A" }}
          _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          h="56px"
        />
        <FormErrorMessage>{firstNameError}</FormErrorMessage>
      </FormControl>

      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired isInvalid={!!phoneNumberError}>
          <FormLabel pt={{ base: "10px", sm: "0px" }}>Phone number</FormLabel>
          <Input
            id="phone"
            name="phone"
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={phoneNumber}
            type="number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            h="56px"
          />
          <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl>
          <FormLabel pt={{ base: "20px", sm: "0px" }}>FAX number</FormLabel>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={faxNumber}
            onChange={(e) => setFaxNumber(e.target.value)}
            h="56px"
          />
        </FormControl>
      </Flex>

      <Flex
        mt={4}
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <Checkbox
          isChecked={isOrderChecked}
          onChange={() => setIsOrderChecked(!isOrderChecked)}
          fontWeight="bold"
          mb={4}
          sx={{
            ".chakra-checkbox__control": {
              width: "12px",
              height: "12px",
              bg: isOrderChecked ? "#DCA672" : "gray.200",
              borderColor: isOrderChecked ? "#DCA672" : "gray.300",
            },
          }}
        >
          Designate a different contact person for orders.
        </Checkbox>
      </Flex>

      {isOrderChecked && (
        <Box w={{ base: "100%", md: "512px" }}>
          <FormControl isRequired={isOrderChecked} isInvalid={!!errors.contactOrderNameError} mb={2}>
            <FormLabel>Contact name</FormLabel>
            <Input
              h="56px"
              border="1px solid #27272A"
              _hover={{ border: "1px solid #27272A" }}
              _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
              value={contactOrderName}
              onChange={(e) => setcontactOrderName(e.target.value)}
            />
            <FormErrorMessage>{errors.contactOrderNameError}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={isOrderChecked} isInvalid={!!errors.contactPhoneError} mb={2}>
            <FormLabel>Contact phone</FormLabel>
            <Input
              h="56px"
              type="number"
              border="1px solid #27272A"
              _hover={{ border: "1px solid #27272A" }}
              _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
            />
            <FormErrorMessage>{errors.contactPhoneError}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Contact email</FormLabel>
            <Input
              h="56px"
              border="1px solid #27272A"
              _hover={{ border: "1px solid #27272A" }}
              _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </FormControl>
        </Box>
      )}

      <Divider pt="10px" pb="10px" />

      <FormControl isRequired isInvalid={!!address1Error}>
        <FormLabel>Address line 1</FormLabel>
        <Input
          border="1px solid #27272A"
          _hover={{ border: "1px solid #27272A" }}
          _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
          h="56px"
        />
        <FormErrorMessage>{address1Error}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel pt={{ base: "10px", sm: "0px" }}>Address line 2</FormLabel>
        <Input
          border="1px solid #27272A"
          _hover={{ border: "1px solid #27272A" }}
          _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
          h="56px"
        />
      </FormControl>

      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired isInvalid={!!cityError}>
          <FormLabel pt={{ base: "10px", sm: "0px" }}>City</FormLabel>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            h="56px"
          />
          <FormErrorMessage>{cityError}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired isInvalid={!!zipCodeError}>
          <FormLabel pt={{ base: "20px", sm: "0px" }}>ZIP / Postal code</FormLabel>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            h="56px"
          />
          <FormErrorMessage>{zipCodeError}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex
        flexDirection="row"
        gap={{ base: 2, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
        pb="48px"
      >
        <FormControl isRequired>
          <FormLabel pt={{ base: "10px", sm: "0px" }}>Country</FormLabel>
          <ChakraSelect
            bg="#C0C0C0"
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            placeholder="USA"
            isDisabled
            w={{ base: "100%", md: "112px" }}
            h="56px"
          />
        </FormControl>

        <VStack alignItems={{ base: "flex-end", md: "flex-start" }}>
          <FormControl isRequired w={{ base: "260px", md: "388px" }}>
            <FormLabel pt={{ base: "20px", sm: "0px" }}>State / Province</FormLabel>
            <FormControl
              isInvalid={!!stateError}
              border="1px solid #27272A"
              _hover={{ border: "1px solid #27272A" }}
              borderRadius="5px"
              h="56px"
              pl="10px"
              pt="14px"
            >
              <Select
                placeholder="Choose your state"
                maxMenuHeight={130}
                variant="unstyled"
                id="states"
                name="states"
                options={options}
                value={state}
                onChange={(e) => setState(e)}
              />
              <FormErrorMessage>{stateError}</FormErrorMessage>
            </FormControl>
          </FormControl>
        </VStack>
      </Flex>

      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
      >
        <Button
          mb={{ base: "20px", sm: "0px" }}
          onClick={onBackClick}
          w="160px"
          h="40px"
          type="button"
          fontSize="14px"
          color="black"
          bg="white"
          border="1px solid black"
          borderRadius="48px"
          fontWeight="700"
          transition="0.3s"
          _hover={{ color: "white", bg: "black" }}
          leftIcon={<IconMathLower stroke="4px" width="12px" height="12px" />}
        >
          BACK
        </Button>
        <Button
          onClick={handleContinueClick}
          w="160px"
          h="40px"
          type="button"
          fontSize="14px"
          color="black"
          bg="white"
          border="1px solid black"
          borderRadius="48px"
          fontWeight="700"
          transition="0.3s"
          _hover={{ color: "white", bg: "black" }}
          rightIcon={<IconMathGreater stroke="4px" width="12px" height="12px" />}
        >
          CONTINUE
        </Button>
      </Flex>
    </VStack>
  );
};

export default Step2;
