import { Divider, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import REGISTER_USER from "../../../types/Registration";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { getAllUSAStates } from "../hooks/getAllUSAStates";
import { navigate } from "gatsby";
import { usePreferredCommunication } from "../../hooks/usePreferredCommunication";
import { useProfessionSelection } from "../../hooks/professionSelection";
import validator from "validator";

const RegisterWrapper = () => {
  const [register, { data }] = useMutation(REGISTER_USER);
  const toast = useToast();
  const wasSignUpSuccessful = Boolean(
    data?.registerCustomer?.customer?.firstName
  );

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const usaStates = getAllUSAStates();

  const parsedStates = useMemo(() => {
    return usaStates.map((state: any) => {
      return { label: state.stateName, value: state.stateCode };
    });
  }, [usaStates]);

  const [activeTab, setActiveTab] = useState(0);

  // STEP 1
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  // STEP 1 ERRORS

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const doPasswordsMatch = password == confirmPassword;
  const [isTermsCheckedError, setIsTermsCheckedError] = useState("");
  const [isAgreementCheckedError, setIsAgreementCheckedError] = useState("");

  // STEP 2

  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [contactOrderName, setContactOrderName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const {
    preferredCommunication,
    setPreferredCommunication,
    otherPreferredCommunication,
    setOtherPreferredCommunication,
    selectedPreferredCommunicationValue,
    preferredCommunicationError,
    setPreferredCommunicationError,
  } = usePreferredCommunication();

  const [state, setState] = useState<
    { value: string; label: string } | undefined
  >(undefined);

  // STEP 2 ERRORS

  const [firstNameError, setFirstNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [stateError, setStateError] = useState("");
  const [contactNameError, setContactNameError] = useState("");
  const [contactPhoneError, setContactPhoneError] = useState("");
  const [isOrderChecked, setIsOrderChecked] = useState(false);

  // STEP 3

  const {
    medicalProfessionalName,
    setMedicalProfessionalName,
    otherMedicalProfessionalName,
    setOtherMedicalProfessionalName,
    selectedMedicalProfessionalNameValue,
    medicalProfessionalNameItems,
    medicalProfessionalNameError,
    setMedicalProfessionalNameError,
    profession,
    setProfession,
    otherProfession,
    setOtherProfession,
    selectedProfessionValue,
    professionItems,
    professionError,
    setProfessionError,
    isProfessionRequired,
    specialty,
    setSpecialty,
    otherSpecialty,
    setOtherSpecialty,
    selectedSpecialtyValue,
    specialtyItems,
    specialtyError,
    setSpecialtyError,
    isSpecialtyRequired,
  } = useProfessionSelection();

  const [contactName, setContactName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpiryDate, setLicenseExpiryDate] = useState<string>(
    new Date().toISOString()
  );

  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState<
    { value: string; label: string } | undefined
  >(undefined);

  // STEP 3 ERRORS

  const [licenseNumberError, setLicenseNumberError] = useState("");
  const [licenseExpiryDateError, setLicenseExpiryDateError] = useState("");

  const [howDidYouHearAboutUsError, setHowDidYouHearAboutUsError] =
    useState("");

  const firstStepValidation = (): void => {
    let errored = false;

    // email check
    if (!validator.isEmail(email)) {
      setEmailError("Enter a valid email address.");
      errored = true;
    } else {
      setEmailError("");
    }

    // password check
    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
        pointsPerUnique: 1,
        pointsPerRepeat: 0.5,
        pointsForContainingLower: 10,
        pointsForContainingUpper: 10,
        pointsForContainingNumber: 10,
        pointsForContainingSymbol: 10,
      })
    ) {
      setPasswordError(
        "Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters, numbers, andspecial characters."
      );
      errored = true;
    } else {
      setPasswordError("");
    }

    // confirm password check
    if (!doPasswordsMatch) {
      setConfirmPasswordError("Passwords don't match");
      errored = true;
    } else {
      setConfirmPasswordError("");
    }

    //check terms checked

    if (!isTermsChecked) {
      setIsTermsCheckedError(
        "You cannot proceed without accepting our privacy policy."
      );
      errored = true;
    } else {
      setIsTermsCheckedError("");
    }

    if (!errored) {
      setActiveTab(1);
      goToTop();
    }
  };

  const secondStepValidation = (): void => {
    let errored = false;

    // Validate first name
    if (!validator.isLength(firstName, { min: 5, max: 50 })) {
      setFirstNameError("Full name is too short.");
      errored = true;
    } else {
      setFirstNameError("");
    }

    // Validate phone number
    if (!validator.isLength(phoneNumber, { min: 4, max: 40 })) {
      setPhoneNumberError("Enter a valid phone number.");
      errored = true;
    } else {
      setPhoneNumberError("");
    }

    // Validate address
    if (!validator.isLength(address1, { min: 4, max: 40 })) {
      setAddress1Error("Enter a valid address.");
      errored = true;
    } else {
      setAddress1Error("");
    }

    // Validate city
    if (!validator.isLength(city, { min: 2, max: 50 })) {
      setCityError("Enter a valid city.");
      errored = true;
    } else {
      setCityError("");
    }

    // Validate ZIP code
    if (!validator.isLength(zipCode, { min: 2, max: 50 })) {
      setZipCodeError("Enter a valid ZIP code.");
      errored = true;
    } else {
      setZipCodeError("");
    }

    // Validate state
    if (!state) {
      setStateError("Choose your state.");
      errored = true;
    } else {
      setStateError("");
    }

    // Validate contact fields if order is checked
    if (isOrderChecked) {
      if (!contactOrderName) {
        setContactNameError("Contact name is required.");
        errored = true;
      } else {
        setContactNameError("");
      }

      if (!contactPhone) {
        setContactPhoneError("Contact phone is required.");
        errored = true;
      } else {
        setContactPhoneError("");
      }
    } 

    // If no errors, move to the next step
    if (!errored) {
      setActiveTab(2);
      goToTop();
    }
  };

  const thirdStepValidation = (): boolean => {
    let errored = false;

    // medical professional name check

    if (
      !selectedMedicalProfessionalNameValue ||
      !validator.isLength(selectedMedicalProfessionalNameValue, {
        min: 2,
        max: 50,
      })
    ) {
      setMedicalProfessionalNameError(
        "Please enter a valid medical professional name."
      );
      errored = true;
    } else {
      setMedicalProfessionalNameError("");
    }

    // medical license number check

    if (!validator.isLength(licenseNumber, { min: 5, max: 50 })) {
      setLicenseNumberError("Medical license number is too short.");
      errored = true;
    } else {
      setLicenseNumberError("");
    }

    //licence expiry date

    if (!validator.isDate(licenseExpiryDate)) {
      setLicenseExpiryDateError("Please enter a valid date.");
      errored = true;
    } else {
      setLicenseExpiryDateError("");
    }

    //check profession

    if (
      isProfessionRequired &&
      (!selectedProfessionValue ||
        !validator.isLength(selectedProfessionValue, {
          min: 2,
          max: 50,
        }))
    ) {
      setProfessionError("Please enter a valid profession.");
      errored = true;
    } else {
      setProfessionError("");
    }

    // specialty check

    if (
      isSpecialtyRequired &&
      (!selectedSpecialtyValue ||
        !validator.isLength(selectedSpecialtyValue, {
          min: 2,
          max: 50,
        }))
    ) {
      setSpecialtyError("Please enter a valid specialty.");
      errored = true;
    } else {
      setSpecialtyError("");
    }

    //how did you hear about us

    if (!howDidYouHearAboutUs) {
      setHowDidYouHearAboutUsError("Choose your answer.");
      errored = true;
    } else {
      setHowDidYouHearAboutUsError("");
    }

    if (
      !selectedPreferredCommunicationValue ||
      !validator.isLength(selectedPreferredCommunicationValue, {
        min: 2,
        max: 50,
      })
    ) {
      setPreferredCommunicationError("Please enter a preferred communication.");
      errored = true;
    } else {
      setPreferredCommunicationError("");
    }

    return errored;
  };

  const handleSubmit = () => {
    const errored = thirdStepValidation();

    if (!errored) {
      register({
        variables: {
          email,
          password,
          confirmPassword,
          firstName,
          phoneNumber,
          faxNumber,
          address1,
          address2,
          city,
          zipCode,
          state: state?.value,
          medicalLicenceNumber: licenseNumber,
          licenceExpirationDate: new Date(licenseExpiryDate!).toISOString(),
          contactOrderName,
          contactPhone,
          contactEmail,
          medicalProfessionalName: selectedMedicalProfessionalNameValue,
          specialty: selectedSpecialtyValue,
          profession: selectedProfessionValue,
          howDidYouHearAboutUs: howDidYouHearAboutUs?.label,
          preferredCommunication: selectedPreferredCommunicationValue,
        },
      }).catch((error: any) => {
        if (error?.message == "Error: Internal server error") {
          navigate("/ty-for-registration");
        } else if (
          error.message.includes(
            `An account is already registered with your email address. <a href="#" class="showlogin">Please log in.</a>`
          )
        ) {
          toast({
            title: "An account is already registered with your email address. ",
            status: "error",
          });
          setEmailError(
            "An account is already registered with your email address. "
          );
          setActiveTab(0);
        } else if (
          error.message.includes(
            `An account is already registered with that username.`
          )
        ) {
          setActiveTab(0);
        } else {
          toast({
            title: error?.message,
            status: "error",
          });
        }
      });
    }
  };

  if (wasSignUpSuccessful) {
    navigate("/ty-for-registration");
  }

  const titleArray = [
    "Account Information",
    "Personal Information",
    "Professional Information",
  ];

  const componentArray = [
    <Step1
      onContinueClick={firstStepValidation}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      emailError={emailError}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
      isTermsChecked={isTermsChecked}
      setIsTermsChecked={setIsTermsChecked}
      isTermsCheckedError={isTermsCheckedError}
    />,
    <Step2
      onContinueClick={secondStepValidation}
      onBackClick={() => {
        setActiveTab(activeTab - 1);
        goToTop();
      }}
      options={parsedStates}
      firstName={firstName}
      setFirstName={setFirstName}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      faxNumber={faxNumber}
      setFaxNumber={setFaxNumber}
      address1={address1}
      setAddress1={setAddress1}
      address2={address2}
      setAddress2={setAddress2}
      city={city}
      setCity={setCity}
      zipCode={zipCode}
      setZipCode={setZipCode}
      state={state}
      setState={setState}
      firstNameError={firstNameError}
      phoneNumberError={phoneNumberError}
      address1Error={address1Error}
      cityError={cityError}
      zipCodeError={zipCodeError}
      stateError={stateError}
      contactOrderName={contactOrderName}
      setcontactOrderName={setContactOrderName}
      contactPhone={contactPhone}
      setContactPhone={setContactPhone}
      contactEmail={contactEmail}
      setContactEmail={setContactEmail}
      contactOrderNameErrorx={contactNameError}
      contactPhoneError={contactPhoneError}
    />,
    <Step3
      onBackClick={() => {
        setActiveTab(activeTab - 1);
        goToTop();
      }}
      onRegisterClick={handleSubmit}
      medicalProfessionalName={medicalProfessionalName}
      setMedicalProfessionalName={setMedicalProfessionalName}
      licenseNumber={licenseNumber}
      setLicenseNumber={setLicenseNumber}
      licenseExpiryDate={licenseExpiryDate}
      setLicenseExpiryDate={setLicenseExpiryDate}
      specialty={specialty}
      setSpecialty={setSpecialty}
      profession={profession}
      setProfession={setProfession}
      medicalProfessionalNameError={medicalProfessionalNameError}
      professionError={professionError}
      specialtyError={specialtyError}
      licenseNumberError={licenseNumberError}
      licenseExpiryDateError={licenseExpiryDateError}
      otherMedicalProfessionalName={otherMedicalProfessionalName}
      setOtherMedicalProfessionalName={setOtherMedicalProfessionalName}
      otherProfession={otherProfession}
      setOtherProfession={setOtherProfession}
      otherSpecialty={otherSpecialty}
      setOtherSpecialty={setOtherSpecialty}
      medicalProfessionalNameItems={medicalProfessionalNameItems}
      professionItems={professionItems}
      specialtyItems={specialtyItems}
      isProfessionRequired={isProfessionRequired}
      isSpecialtyRequired={isSpecialtyRequired}
      contactName={contactName}
      setContactName={setContactName}
      howDidYouHearAboutUs={howDidYouHearAboutUs}
      setHowDidYouHearAboutUs={setHowDidYouHearAboutUs}
      howDidYouHearAboutUsError={howDidYouHearAboutUsError}
      preferredCommunication={preferredCommunication}
      setPreferredCommunication={setPreferredCommunication}
      otherPreferredCommunication={otherPreferredCommunication}
      setOtherPreferredCommunication={setOtherPreferredCommunication}
      isPreferredCommunicationError={preferredCommunicationError}
    />,
  ];

  return (
    <VStack
      pl={{ base: "10px", sm: "30px", md: "80px", lg: "120px", xl: "210px" }}
      pr={{ base: "10px", sm: "30px", md: "80px", lg: "120px", xl: "210px" }}
      mx="auto"
      maxW="1612px"
      fontFamily="Montserrat"
      mt="40px"
      pb="80px"
      pt={{ base: "200px", lg: "48px" }}
    >
      <Divider
        width="24px"
        borderRadius="6px"
        pt="2px"
        bg="#BD2A29"
        borderColor="#BD2A29"
      />
      <Text
        as="h1"
        bgGradient="linear(to-r, #262626, #8C8C8C)"
        bgClip="text"
        mt="24px"
        mb="16px"
        fontSize="36px"
        textAlign="center"
      >
        Register your free account
      </Text>
      <Text textAlign="center" fontSize="16px" pb="48px">
        Join hundreds of medical professionals
      </Text>

      <HStack pb="24px">
        <HStack fontWeight="bold" spacing={0}>
          <Text color="#DCA672">{activeTab + 1}</Text>
          <Text color={activeTab === 2 ? "#DCA672" : "#8C8C8C"}>/ 3</Text>
        </HStack>
        <Text fontWeight="bold">{titleArray[activeTab]}</Text>
      </HStack>

      <HStack
        justifyContent="center"
        width="full"
        spacing={2}
        w={{ base: "100%", md: "512px" }}
        mx="auto"
        pb={4}
      >
        <Divider
          maxW="165px"
          borderRadius="6px"
          pt="8px"
          bg="#DCA672"
          borderColor="#DCA672"
        />
        <Divider
          maxW="165px"
          borderRadius="6px"
          pt="8px"
          bg={activeTab >= 1 ? "#DCA672" : "#D9D9D9"}
          borderColor={activeTab >= 1 ? "#DCA672" : "#D9D9D9"}
        />
        <Divider
          maxW="165px"
          borderRadius="6px"
          pt="8px"
          bg={activeTab === 2 ? "#DCA672" : "#D9D9D9"}
          borderColor={activeTab === 2 ? "#DCA672" : "#D9D9D9"}
        />
      </HStack>
      {componentArray[activeTab]}
    </VStack>
  );
};

export default RegisterWrapper;
