import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Text,
  Input,
  ListItem,
  Stack,
  Tooltip,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { IconMathGreater, IconMathLower } from "@tabler/icons";
import React, { useState } from "react";

import FormInput from "./FormInput";
import { PREFERRED_COMMUNICATION } from "../../constants/ preferredCommunication";
import ProfessionSelection from "../ProfessionSelection";
import { Select } from "chakra-react-select";

const Step3 = ({
  onBackClick,
  onRegisterClick,
  medicalProfessionalName,
  setMedicalProfessionalName,
  licenseNumber,
  setLicenseNumber,
  licenseExpiryDate,
  otherMedicalProfessionalName,
  setLicenseExpiryDate,
  contactName,
  setContactName,
  profession,
  setProfession,
  specialty,
  setSpecialty,
  howDidYouHearAboutUs,
  setHowDidYouHearAboutUs,
  medicalProfessionalNameError,
  licenseNumberError,
  licenseExpiryDateError,
  professionError,
  setOtherMedicalProfessionalName,
  otherProfession,
  setOtherProfession,
  otherSpecialty,
  setOtherSpecialty,
  medicalProfessionalNameItems,
  professionItems,
  specialtyItems,
  isProfessionRequired,
  isSpecialtyRequired,
  specialtyError,
  howDidYouHearAboutUsError,
  preferredCommunication,
  setPreferredCommunication,
  otherPreferredCommunication,
  setOtherPreferredCommunication,
  isPreferredCommunicationError,
}: any) => {
  const options = [
    { value: "google", label: "Google" },
    { value: "social media", label: "Social media" },
    { value: "email", label: "Email" },
    { value: "sms", label: "SMS" },
    { value: "fax", label: "Fax" },
    { value: "word of mouth", label: "Word of mouth" },
    { value: "bing", label: "Bing" },
  ];

  const tooltipContent = (  
    <>
    <Text>We accept these licenses only:</Text>
    <UnorderedList m={0} fontSize="xs" fontWeight={400} lineHeight="150%">
      <ListItem>Medical Doctor</ListItem>
      <ListItem>Osteopathic Doctor</ListItem>
      <ListItem>Doctor of Medical Dentistry</ListItem>
      <ListItem>Doctor of Dental Surgery</ListItem>
      <ListItem>Doctor of Podiatry Medicine</ListItem>
      <ListItem>Physician Assistant</ListItem>
      <ListItem>Nurse Practitioner</ListItem>
      <ListItem>Advanced Registered Nurse Practitioner</ListItem>
      <ListItem>Advanced Practice Registered Nurse</ListItem>
      <ListItem>Family Nurse Practitioner</ListItem>
      <ListItem>Advanced Practice Nurse</ListItem>
      <ListItem>Doctor of Veterinary Medicine</ListItem>
    </UnorderedList>
    </>
  );

  const contact = (
    <Text>
      Select your preferred way to receive communications from our Customer
      Support team.
    </Text>
  );

  const {
    isOpen: isTooltip1Open,
    onOpen: onTooltip1Open,
    onClose: onTooltip1Close,
    onToggle: onTooltip1Toggle,
  } = useDisclosure();

  const {
    isOpen: isTooltip2Open,
    onOpen: onTooltip2Open,
    onClose: onTooltip2Close,
    onToggle: onTooltip2Toggle,
  } = useDisclosure();

  return (
    <VStack width="full" mx="auto" maxW="1612px">
      <ProfessionSelection
        fontWeight="500"
        medicalProfessionalName={medicalProfessionalName}
        setMedicalProfessionalName={setMedicalProfessionalName}
        medicalProfessionalNameError={medicalProfessionalNameError}
        profession={profession}
        setProfession={setProfession}
        specialty={specialty}
        setSpecialty={setSpecialty}
        otherMedicalProfessionalName={otherMedicalProfessionalName}
        setOtherMedicalProfessionalName={setOtherMedicalProfessionalName}
        otherProfession={otherProfession}
        setOtherProfession={setOtherProfession}
        otherSpecialty={otherSpecialty}
        setOtherSpecialty={setOtherSpecialty}
        medicalProfessionalNameItems={medicalProfessionalNameItems}
        professionItems={professionItems}
        specialtyItems={specialtyItems}
        isProfessionRequired={isProfessionRequired}
        isSpecialtyRequired={isSpecialtyRequired}
        professionError={professionError}
        specialtyError={specialtyError}
      />

      {/* <FormControl isRequired isInvalid={!!medicalProfessionalNameError}>
        <FormLabel>Medical Professional Name</FormLabel>
        <Input
          border="1px solid #27272A"
          _hover={{ border: "1px solid #27272A" }}
          _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
          value={medicalProfessionalName}
          onChange={(e) => setMedicalProfessionalName(e.target.value)}
        />
        <FormErrorMessage>{medicalProfessionalNameError}</FormErrorMessage>
      </FormControl> */}
      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired isInvalid={!!licenseNumberError}>
          <Stack
            display="flex"
            flexFlow="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={0}
          >
            <FormLabel mr={2}>License number</FormLabel>{" "}
            <Tooltip
              label={tooltipContent}
              isOpen={isTooltip1Open}
              cursor="pointer"
              placement="top"
              padding={"6px 8px"}
              fontSize="md"
              bg="#120B0C"
              color="#fff"
              borderRadius="6px"
            >
              <Image
                src="/information-line.svg"
                mt="-4px"
                mb="0.5rem"
                onMouseEnter={onTooltip1Open}
                onMouseLeave={onTooltip1Close}
                onClick={onTooltip1Toggle}
              />
            </Tooltip>
          </Stack>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            h="56px"
          />
          <FormErrorMessage>{licenseNumberError}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired isInvalid={!!licenseExpiryDateError}>
          <FormLabel pt={{ base: "10px", sm: "0px" }}>
            License expiry date
          </FormLabel>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            type="date"
            value={licenseExpiryDate}
            onChange={(e) => setLicenseExpiryDate(e.target.value)}
            h="56px"
          />
          <FormErrorMessage>{licenseExpiryDateError}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired>
          <FormLabel pt={{ base: "10px", sm: "0px" }}>Name on the license</FormLabel>
          <Input
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            _placeholder={{ opacity: 1, color: "#BFBFBF !important" }}
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            h="56px"
          />
        </FormControl>
      </Flex>
      <Flex
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired>
          <FormLabel>How did you hear about us?</FormLabel>
          <FormControl
            isInvalid={!!howDidYouHearAboutUsError}
            border="1px solid #27272A"
            _hover={{ border: "1px solid #27272A" }}
            borderRadius="5px"
            height="40px"
            pt={"14px"}
            pl="10px"
            h="56px"
          >
            <Select
              maxMenuHeight={130}
              variant="unstyled"
              id="how-did-you-hear-about-us"
              name="howDidYouHearAboutUs"
              options={options}
              value={howDidYouHearAboutUs}
              onChange={(e) => setHowDidYouHearAboutUs(e)}
            />
            <FormErrorMessage>{howDidYouHearAboutUsError}</FormErrorMessage>
          </FormControl>
        </FormControl>
      </Flex>
      <Flex
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
        w={{ base: "100%", md: "512px" }}
      >
        <FormControl isRequired mt="12px" mb={{ base: "0px", md: "24px" }}>
          <Stack
            display="flex"
            flexFlow="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={0}
          >
            <FormLabel>
              Preferred methods for customer support contact
            </FormLabel>
            <Tooltip
              label={contact}
              isOpen={isTooltip2Open}
              cursor="pointer"
              placement="top"
              padding={"6px 8px"}
              fontSize="md"
              bg="#120B0C"
              color="#fff"
              borderRadius="6px"
            >
              <Image
                src="/information-line.svg"
                mt="-4px"
                mb="0.5rem"
                onMouseEnter={onTooltip2Open}
                onMouseLeave={onTooltip2Close}
                onClick={onTooltip2Toggle}
              />
            </Tooltip>
          </Stack>
          <FormControl
            isInvalid={
              preferredCommunication?.value !== "Others" &&
              !!isPreferredCommunicationError
            }
            border={
              preferredCommunication?.value !== "Others" &&
              isPreferredCommunicationError
                ? "2px solid red"
                : "1px solid #27272A"
            }
            _hover={{ border: "1px solid #27272A" }}
            borderRadius="5px"
            height="40px"
            pl="10px"
            pt={"14px"}
            h="56px"
          >
            <Select
              options={PREFERRED_COMMUNICATION}
              selectedOptionColorScheme="black"
              variant="unstyled"
              maxMenuHeight={130}
              id="preferredCommunication"
              name="preferredCommunication"
              value={preferredCommunication}
              onChange={(e) => {
                if (e) {
                  setPreferredCommunication(e);
                }
              }}
            />
            <FormErrorMessage pt="10px">
              {isPreferredCommunicationError}
            </FormErrorMessage>
          </FormControl>
          {preferredCommunication?.value === "Others" && (
            <FormInput
              height="40px"
              isInvalid={!!isPreferredCommunicationError}
              error={isPreferredCommunicationError}
              value={otherPreferredCommunication}
              setValue={setOtherPreferredCommunication}
              placeholder="Enter preferred communication"
            />
          )}
        </FormControl>
      </Flex>
      <Flex
        flexDirection={{ base: "column", sm: "row" }}
        gap={{ base: 0, sm: 4 }}
        alignItems="flex-start"
      >
        <Button
          mt={{ base: "0px", sm: "48px" }}
          mb={{ base: "20px", sm: "0px" }}
          onClick={onBackClick}
          w="160px"
          h="40px"
          type="button"
          fontSize="14px"
          color="black"
          bg="white"
          border="1px solid black"
          borderRadius="48px"
          fontWeight="700"
          transition="0.3s"
          _hover={{ color: "white", bg: "black" }}
          leftIcon={<IconMathLower stroke="4px" width="12px" height="12px" />}
        >
          BACK
        </Button>
        <Button
          mt={{ base: "0px", sm: "48px" }}
          onClick={onRegisterClick}
          w="160px"
          h="40px"
          type="button"
          fontSize="14px"
          color="black"
          bg="white"
          border="1px solid black"
          borderRadius="48px"
          fontWeight="700"
          transition="0.3s"
          _hover={{ color: "white", bg: "black" }}
          rightIcon={
            <IconMathGreater stroke="4px" width="12px" height="12px" />
          }
        >
          REGISTER
        </Button>
      </Flex>
    </VStack>
  );
};

export default Step3;
